
import { DateTime } from 'luxon';

const DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm:ss';
const SHORT_DATE_FORMAT = 'dd/MM/yyyy';
const FORM_DATE_FORMAT = 'yyyy-MM-dd';
const LANG_ES = 'es';

export function isoToDateTime(input: string): string
{
	const date = DateTime.fromISO(input);
	return date.toFormat(DATE_TIME_FORMAT);
}
export function isoToFormDateTime(input: string): string
{
	const date = DateTime.fromISO(input);
	return date.toFormat(FORM_DATE_FORMAT);
}

export function isoToShortDate(input: string): string
{
	const date = DateTime.fromISO(input);
	return date.toFormat(SHORT_DATE_FORMAT);
}
export function isoToDateYear(input: string): string
{
	const date = DateTime.fromISO(input);
	return date.toFormat('yyyy');
}
export function isoToLongDate(input: string): string
{
	const date = DateTime.fromISO(input, { locale: LANG_ES });
	return date.toFormat("dd 'de' LLLL 'de' yyyy");
}

export function localIsoToShortDate(input: string): string
{
	const date = DateTime.fromISO(input);
	const utc = date.toUTC();
	return utc.toFormat(SHORT_DATE_FORMAT);
}

export function dateToIsoDateString(date: Date)
{
	const luxonDate = DateTime.fromJSDate(date);
	return luxonDate.toISODate() as string;
}

export function dateToShortDate(date: Date)
{
	const luxonDate = DateTime.fromJSDate(date);
	return luxonDate.toFormat(SHORT_DATE_FORMAT);
}

export function minJSDate(date1: Date, date2: Date): Date
{
	return date1 < date2 ? date1 : date2;
}

export function maxJSDate(date1: Date, date2: Date): Date
{
	return date1 > date2 ? date1 : date2;
}

export function jsDateInRange(date: Date, min: Date, max: Date): Date
{
	const temp = maxJSDate(date, min);
	return minJSDate(temp, max);
}

function formatDate(date: Date)
{
	if (!date)
		return '';
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	return `${day}/${month}/${year}`;
}

export function formatDateRangePreview([from, to]: Date[])
{
	const fromString = formatDate(from);
	const toString = formatDate(to);
	return `${fromString} - ${toString}`;
}
