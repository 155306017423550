import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Contract, LoginActionState, User } from "src/util/types";
import { loginUser, updatePassword, uploadCertificate, uploadRubric } from "./thunk";

interface LoginStateType {
    error?: string | object | null | undefined | unknown,
    user: User,
    history?: any
    contracts: Contract[],
    contractSelected: Contract | null;
    configProfileStatus: LoginActionState;
    configProfileMsg: string;
    updatePasswordStatus: LoginActionState;
    updatePasswordMsg: string;
}
export const initialState: LoginStateType = {
    error: '',
    user: {
        id: "",
        email: "",
        name: "",
        contracts: [],
        tokenVersion: 0,
        token: "",
        profile: '',
        hasCertificate: false,
        hasRubric: false
    },
    contracts: [],
    history: '',
    contractSelected: {
        id: '',
        name: ''
    },
    configProfileStatus: LoginActionState.NOT_STARTED,
    configProfileMsg: '',
    updatePasswordStatus: LoginActionState.NOT_STARTED,
    updatePasswordMsg: ''
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        apiError(state, action) {
            state.error = action.payload.message
        },
        contractChange(state, action: PayloadAction<Contract>) {
            state.contractSelected = action.payload
        },
        onFinishUploadCertificate(state) {
            state.configProfileStatus = LoginActionState.NOT_STARTED;
            state.configProfileMsg = '';
        },
        resetState(state) {
            state.configProfileStatus = LoginActionState.NOT_STARTED;
            state.configProfileMsg = '';   
            state.updatePasswordStatus = LoginActionState.NOT_STARTED;
            state.updatePasswordMsg = '';
        },
        cleanError(state) {
            state.error = '';
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.error = '';
            })
            .addCase(loginUser.fulfilled, (state, action: PayloadAction<User | undefined>) => {
                if( action.payload ) {
                    state.user = action.payload
                    state.contracts = action.payload.contracts
                    state.contractSelected = (action.payload.contracts) ? action.payload.contracts[0] : null
                };
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(uploadCertificate.pending, (state) => {
                state.error = '';
                state.configProfileStatus = LoginActionState.LOADING;
            })  
            .addCase(uploadCertificate.fulfilled, (state, action: PayloadAction<User>) => {
                state.user = action.payload;
                state.configProfileMsg = 'Certificado subido correctamente';
                state.configProfileStatus = LoginActionState.SUCCESS;
                state.error = ''
            })  
            .addCase(uploadCertificate.rejected, (state, action) => {
                state.error = action.payload
                state.configProfileStatus = LoginActionState.ERROR;
                state.configProfileMsg = 'Error al subir el certificado';
            })
            .addCase(uploadRubric.pending, (state) => {
                state.error = '';
                state.configProfileStatus = LoginActionState.LOADING;
            })  
            .addCase(uploadRubric.fulfilled, (state, action: PayloadAction<User>) => {
                state.user = action.payload;
                state.configProfileMsg = 'Rubrica subida correctamente';
                state.configProfileStatus = LoginActionState.SUCCESS;
                state.error = ''
            })  
            .addCase(uploadRubric.rejected, (state, action) => {
                state.error = action.payload
                state.configProfileStatus = LoginActionState.ERROR;
                state.configProfileMsg = 'Error al subir la rúbrica.';
            })
            .addCase(updatePassword.pending, (state) => {
                state.error = '';
                state.updatePasswordStatus = LoginActionState.LOADING;
            })
            .addCase(updatePassword.fulfilled, (state) => {
                state.updatePasswordMsg = 'Clave actualizada correctamente';
                state.updatePasswordStatus = LoginActionState.SUCCESS;
                state.error = ''
            })
            .addCase(updatePassword.rejected, (state, action) => {
                state.error = action.payload
                state.updatePasswordStatus = LoginActionState.ERROR;
                state.updatePasswordMsg = 'Error al actualizar la clave';
            })
    }
});

export const {
    apiError,
    contractChange,
    onFinishUploadCertificate,
    resetState,
    cleanError,
} = loginSlice.actions

export default loginSlice.reducer;