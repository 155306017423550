// action
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiError } from "./reducer";

import { changePassword, login, requestTempFileUpload, saveUserCertificate, saveUserSignatureImage, uploadFile } from "src/api/api";
import { User } from "src/util/types";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (
    { email, password, navigate }: { email: string; password: string, navigate: any },
    { rejectWithValue }
  ) => {
    try {
      const user: User = await login(email, password);
      if (user) {
        navigate("/repositorio/general");
        return user;
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const logoutUser: any = (history: any) => async (dispatch: any) => {
  try {
    localStorage.removeItem("authUser");
    history("/login");
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const uploadCertificate = createAsyncThunk(
  'login/uploadCertificate',
  async (file: any, { rejectWithValue }) => {
    try {
      const tempFile = await requestTempFileUpload();
      await uploadFile(tempFile.url, file);
      return await saveUserCertificate(tempFile.file);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const uploadRubric = createAsyncThunk(
  'login/uploadRubric',
  async (file: any, { rejectWithValue }) => {
    try {
      const tempFile = await requestTempFileUpload();
      await uploadFile(tempFile.url, file);
      return await saveUserSignatureImage(tempFile.file);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const updatePassword = createAsyncThunk(
  'login/updatePassword',
  async (
    newPassword: string,
    { rejectWithValue }
  ) => {
    try {
      await changePassword(newPassword);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

