import { createAsyncThunk } from '@reduxjs/toolkit';

import { getMessage, getMessages, requestTempFileUpload, sendMessage, uploadFile } from '../../api/api';
import { saveDraft } from '../../api/api';
import { RootState } from 'src';
import { MessageDraft, MessageDraftRequest, TempAttachment } from 'src/util/types';

export const fetchCommunications = createAsyncThunk(
  'communications/fetchCommunications',
  async (_, {  getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const { contractSelected } = state.login;

      if( !contractSelected ) return rejectWithValue('No se ha seleccionado un contrato.');
      return await getMessages(contractSelected.id);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCommunicationById = createAsyncThunk(
  'communications/fetchCommunicationById',
  async (id: string, { rejectWithValue }) => {
    try {
      return await getMessage(id);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewCommResponse = createAsyncThunk(
  'communications/addNewCommResponse',
  async (newComm: MessageDraft, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const { contractSelected } = state.login;
      const { selected_comm } = state.communications;

      if(!selected_comm) return rejectWithValue('No se ha seleccionado una comunicación.');

      if( !contractSelected ) return rejectWithValue('No se ha seleccionado un contrato.');

      const commResponse: MessageDraftRequest ={
        ...newComm,
        contractid: contractSelected.id,
        parentid: selected_comm.id
      }

      return await saveDraft(commResponse);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const addNewComm = createAsyncThunk(
  'communications/addNewComm',
  async (newComm: MessageDraft, { getState, rejectWithValue,  }) => {
    try {
      const state = getState() as RootState;
      const { contractSelected } = state.login;

      if( !contractSelected ) return rejectWithValue('No se ha seleccionado un contrato.');

      const saveDraftRequest: MessageDraftRequest = {
        ...newComm,
        contractid: contractSelected.id,
        parentid: null
      }; 
      const id = await saveDraft(saveDraftRequest);
      return id;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const validatePassForSignComm = createAsyncThunk(
  'communications/validatePassForSignComm',
  async ({password, commId}:{password: string, commId: string }, { rejectWithValue }) => {
    try {
      await sendMessage(commId, password)
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
)

export const addTempAttachments = createAsyncThunk<TempAttachment[], File[], { rejectValue: string }>(
  'communications/addTempAttachments',
  async (attachments: File[], { rejectWithValue }) => {
    try {
      let tempFiles: TempAttachment[] = [];
      for(const file of attachments) {
        const tempFile = await requestTempFileUpload();
        await uploadFile(tempFile.url, file);
        const tempFileObj: TempAttachment = {
          tempFile: tempFile.file,
          filename: file.name
        }
        tempFiles.push(tempFileObj);
      }
      return tempFiles;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

