import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { addDraftComm, deleteDraftComm, fetchDraftComms, fetchMessageTypes, getDraftById, updateDraftComm } from './thunks';
import { CommActionState, MessageDraft, MessageSummary, MessageType } from '../../util/types';

interface DraftCommState {
  draftComms: MessageSummary[];
  draftStatus: CommActionState;
  draftUpdateStatus: CommActionState;
  draftAddStatus: CommActionState;
  draftUpdateMsg: string;
  draftMsg: string;
  draftSelected: MessageDraft | null;
  draftDeleteStatus: CommActionState
  draftDeleteMsg: string
  messageTypes: MessageType[];
}

const initialState: DraftCommState = {
  draftComms: [],
  draftStatus: CommActionState.NOT_STARTED,
  draftUpdateStatus: CommActionState.NOT_STARTED,
  draftAddStatus: CommActionState.NOT_STARTED,
  draftUpdateMsg: '',
  draftMsg: '',
  draftSelected: null,
  draftDeleteStatus: CommActionState.NOT_STARTED,
  draftDeleteMsg: '',
  messageTypes: []
};

export const draftCommsSlice = createSlice({
  name: 'draftComms',
  initialState,
  reducers: {
    resetDraftState: () => initialState,
    resetDraftStatus: (state) => {
      state.draftStatus = CommActionState.NOT_STARTED;
      state.draftMsg = '';
      state.draftUpdateStatus = CommActionState.NOT_STARTED;
      state.draftUpdateMsg = '';
      state.draftDeleteStatus = CommActionState.NOT_STARTED;
      state.draftDeleteMsg = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDraftComms.pending, (state) => {
        state.draftStatus = CommActionState.LOADING;
      })
      .addCase(fetchDraftComms.fulfilled, (state, action: PayloadAction<MessageSummary[]>) => {
        state.draftStatus = CommActionState.SUCCESS;
        state.draftComms = action.payload;
      })
      .addCase(fetchDraftComms.rejected, (state, action) => {
        state.draftStatus = CommActionState.ERROR;
        state.draftMsg = action.error.message ?? 'Error al cargar los borradores.';
      })
      .addCase(addDraftComm.pending, (state) => {
        state.draftAddStatus = CommActionState.LOADING;
      })
      .addCase(addDraftComm.fulfilled, (state) => {
        state.draftAddStatus = CommActionState.SUCCESS;
        state.draftMsg = 'Borrador guardado exitosamente.'
      })
      .addCase(addDraftComm.rejected, (state, action) => {
        state.draftAddStatus = CommActionState.ERROR;
        state.draftMsg = action.error.message ?? 'Error al agregar el borrador.';
      })
      .addCase(updateDraftComm.pending, (state) => {
        state.draftUpdateStatus = CommActionState.LOADING;
      })
      .addCase(updateDraftComm.fulfilled, (state) => {
        state.draftUpdateStatus = CommActionState.SUCCESS;
        state.draftUpdateMsg = 'Borrador actualizado exitosamente.';
      })
      .addCase(updateDraftComm.rejected, (state) => {
        state.draftUpdateStatus = CommActionState.ERROR;
        state.draftUpdateMsg = 'Error al actualizar el borrador.';
        state.draftSelected = null;
      })
      .addCase(deleteDraftComm.pending, (state) => {
        state.draftDeleteStatus = CommActionState.LOADING;
      })
      .addCase(deleteDraftComm.fulfilled, (state) => {
        state.draftDeleteStatus = CommActionState.SUCCESS;
        state.draftDeleteMsg = 'Borrador eliminado exitosamente.';
      })
      .addCase(deleteDraftComm.rejected, (state, action) => {
        state.draftDeleteStatus = CommActionState.ERROR;
        state.draftDeleteMsg = action.error.message ?? 'Error al eliminar el borrador.';
      })
      .addCase(fetchMessageTypes.fulfilled, (state, action: PayloadAction<MessageType[]>) => {
        state.messageTypes = action.payload;
      })
  },
});
export const { resetDraftState, resetDraftStatus } = draftCommsSlice.actions;
export default draftCommsSlice.reducer;