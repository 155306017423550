import { Alert, Progress, Spinner } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { ActionState } from 'src/util/types';

interface StatusAlertProps {
  status: ActionState;
  msg: string;
  progressBar?: boolean,
  progressTime?: number
}

export const StatusAlert: React.FC<StatusAlertProps> = ({ status, msg, progressBar = false, progressTime = 1000 }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if(status === ActionState.SUCCESS && progressBar) {
      loadProgressBar()
    }
  }, [status])
  

  const loadProgressBar = () => {
    let currentProgress = 0;
    const interval = setInterval(() => {
      currentProgress += progressTime/100;
      setProgress(currentProgress);
      if (currentProgress >= 100) { clearInterval(interval); }
    }, 100);
  }

  return (
    <>
      {status === ActionState.LOADING && <Spinner className="ms-2" color="primary" />}

      {status === ActionState.ERROR && (<Alert fade={false} color="danger" className="mt-4">{msg}</Alert>)}

      {status === ActionState.SUCCESS && (
        <React.Fragment>
          <Alert fade={false} color="success" className="mt-4">{msg}</Alert>
          {
            progressBar && <Progress value={progress} color="success" animated striped></Progress>
          }
        </React.Fragment>
      )}
    </>
  );
};

