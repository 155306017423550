import { Button, Collapse, Spinner } from 'reactstrap';
import classnames from "classnames";
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react'

import { Message } from 'src/util/types';
import { downloadMessagePdf } from 'src/api/api';
import { isoToDateYear, isoToLongDate } from 'src/util/date';


export const CommDetail: React.FC<{selectedComm: Message, isResponse: boolean}> = ({selectedComm, isResponse}) => {
  const [openComm, setOpenComm] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false)

  useEffect(() => {
    if(!isResponse){
      setOpenComm(true);
    }
    if(isResponse && !selectedComm.read) {
      setOpenComm(true);
    }
  }, [])


  const toggleComm = () => {
    setOpenComm(!openComm);
  }

  const sanitizedHTML = (html: string) => {
    return DOMPurify.sanitize(html);
  }

  const downloadPdf = async () => {
    setIsDownloading(true);
    const url = await downloadMessagePdf(selectedComm.id)
    setIsDownloading(false);
    window.location.href = url;
  }

  return (
    <div className="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              { collapsed: !openComm }
            )}
            type="button"
            onClick={toggleComm}
            style={{ cursor: "pointer" }}
          >
            <div className="flex-1">
              <h5 className="font-size-14 my-1">
                {selectedComm.type?.toUpperCase()} {selectedComm.sequence} / {isoToDateYear(selectedComm.date)}
              </h5>
            </div>
          </button>
        </h2>
        <Collapse isOpen={openComm}>
          <div className="p-3" style={{ color: "black" }}>
            <h4 className='text-center mt-2'>{selectedComm.type?.toUpperCase()} {selectedComm.sequence} / {isoToDateYear(selectedComm.date)}</h4>
            
            <p className="text-end">La Reina, {isoToLongDate(selectedComm.date)}</p>
            
            <p><strong>Contrato:</strong> {selectedComm.contract}</p>
            <p><strong>De:</strong> {selectedComm.source}</p>
            <p><strong>Para:</strong> {selectedComm.destination}</p>

            <p className='mt-2' dangerouslySetInnerHTML={{ __html: sanitizedHTML(selectedComm.body) }}></p>

            <Button
              onClick={downloadPdf}
              className='mt-4'
              disabled={isDownloading}
              type="button"
              color='dark'
              outline
            >
              {
                isDownloading ?
                  <Spinner color="dark" size="sm" ></Spinner>
                  :
                  <><i className="mdi mdi-file-pdf-outline"></i> Descargar PDF</>
              }
            </Button>
          </div>

        </Collapse>
      </div>
    </div>
  )
}
