import React from "react";

import { Row, Col, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";

//redux
import { useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

// withRouter
import withRouter from "src/components/Common/withRouter";

// actions
import { loginUser } from "../../slices/thunks";

//import images
import logo from "../../assets/images/logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createSelector } from "reselect";
import { useAppDispatch } from "src/hooks/useAppDispatch";


interface LoginProps {
  props: any;
}

const Login = ({ props }: LoginProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  document.title = "Login | Libro de Contratos Digital";

  const selectLeadData = createSelector(
    (state:any) => state.login.error,
    (leadsChart) => leadsChart
  );
  // Inside your component
  const error = useSelector(selectLeadData);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Por favor ingresa tu correo"),
      password: Yup.string().required("Por favor ingresa tu contraseña"),
    }),
    onSubmit: values => {
      dispatch(loginUser({email: values.email, password: values.password, navigate}));
    },
  });


  return (
    <React.Fragment>
      <div className="authentication-bg min-vh-100">
        <div className="bg-overlay bg-white"></div>
        <Container>
          <div className="d-flex flex-column min-vh-100 px-3 pt-4">
            <Row className="justify-content-center my-auto">
              <Col md={8} lg={6} xl={4}>
                <div className="text-center py-5">
                  <div className="mb-4 mb-md-5">
                    <Link to="/sales" className="d-block auth-logo">
                      <img
                        src={logo}
                        alt=""
                        height="100"
                        className="auth-logo-dark"
                      />
                      <img
                        src={logo}
                        alt=""
                        height="100"
                        className="auth-logo-light"
                      />
                    </Link>
                  </div>
                  {error && error ? (<Alert className="alert-danger"> {error} </Alert>) : null}
                  <Form
                    onSubmit={e => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="form-floating form-floating-custom text-start mb-3">
                      <Input
                        type="text"
                        name="email"
                        className="form-control"
                        id="input-username"
                        placeholder="Enter User Name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                      <Label htmlFor="input-username">Correo</Label>
                      <div className="form-floating-icon">
                        <i className="uil uil-users-alt"></i>
                      </div>
                    </div>
                    <div className="form-floating form-floating-custom text-start mb-3">
                      <Input
                        name="password"
                        type="password"
                        className="form-control"
                        id="input-password"
                        placeholder="Enter Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ""}
                        invalid={
                          validation.touched.password &&
                            validation.errors.password
                            ? true
                            : false
                        }
                      />
                      {validation.touched.password &&
                        validation.errors.password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}
                      <Label htmlFor="input-password">Contraseña</Label>
                      <div className="form-floating-icon">
                        <i className="uil uil-padlock"></i>
                      </div>
                    </div>

                    <div className="form-check form-check-info font-size-16">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="remember-check"
                      />
                      <Label
                        className="form-check-label font-size-14"
                        htmlFor="remember-check"
                      >
                        Mantener sesión abierta
                      </Label>
                    </div>

                    <div className="mt-3">
                      <button className="btn btn-info w-100" type="submit">
                        Ingresar
                      </button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};