import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Alert, Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import { Link, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import draftToHtml from "draftjs-to-html";
import React, { useEffect, useState } from 'react';

import { addNewCommResponse, fetchCommunicationById, validatePassForSignComm } from 'src/slices/Communications/thunks';
import { CommActionState, MessageDraft } from 'src/util/types';
import { CommDetail } from "./CommDetail";
import { RootState } from 'src';
import { StatusAlert } from "src/components/Common/StatusAlert";
import { useAppDispatch } from 'src/hooks/useAppDispatch';

export const OpenComm = () => {
  const dispatch = useAppDispatch();
  const { selected_comm, selected_comm_msg, selected_comm_status, comm_responses, savedCommId, validating_status, validating_msg } = useSelector((state: RootState) => state.communications);
  const [signModal, setSignModal] = useState(false);  
  // Modal para respuesta
  const [modal, setmodal] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const timeToAct = 1000;


  document.title = "Ver comunicación";

  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchCommunicationById(id));
  }, [id]);

  useEffect(() => {
    if(validating_status === CommActionState.SUCCESS) {
      setEditorState(EditorState.createEmpty());
      signFormik.resetForm();

      const timeout = setTimeout(() => {
        dispatch(fetchCommunicationById(id));
        toggleSignModal();
      }, timeToAct);
      
      return () => {
        clearTimeout(timeout);
      }
    }

  }, [validating_status]);

  const handleEditorChange = (state: EditorState) => {
    setEditorState(state);

    const contentAsHtml = draftToHtml(convertToRaw(state.getCurrentContent()));
    formik.setFieldValue("editorContent", contentAsHtml);
  };

  const formik = useFormik({
    initialValues: {
      editorContent: "",
      attachedFiles: null
    },
    onSubmit: async (values) => {
      try {
        const comm_response: MessageDraft = {
          body: values.editorContent,
          typeid: selected_comm?.typeid ?? '',
          deadline: null,
          attachments: []
        };

        await dispatch(addNewCommResponse(comm_response)).unwrap();
        toggleBetweenModal();
      } catch (error) {
        console.error('Error al enviar la respuesta:', error);
      }
    }
  });

  const toggleBetweenModal = () => {
    setmodal(false);
    toggleSignModal()
  }

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  }

  const toggleSignModal = () => {
    setSignModal(!signModal);
    removeBodyCss();
  }

  const signFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().required('La contraseña es requerida.')
    }),
    onSubmit: async ({ password }) => {
      if(savedCommId) {
        dispatch(validatePassForSignComm({ password, commId: savedCommId }));
      }
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {
                    (selected_comm_status === CommActionState.LOADING) &&
                    <Spinner
                      className="spinner-border text-primary mt-4"
                      role="status"
                    ></Spinner>
                  }
                  {
                    (selected_comm_status === CommActionState.ERROR) &&
                    <CardBody>
                      <Alert color="danger">
                        {selected_comm_msg}
                      </Alert>
                    </CardBody>
                  }
                  {
                    (selected_comm) &&
                    <>
                      <CommDetail selectedComm={selected_comm} isResponse={false} ></CommDetail>
                      <Link
                        to="#"
                        className="btn btn-light waves-effect mt-4"
                        onClick={() => { setmodal(!modal); }}>
                        <i className="mdi mdi-reply"></i> Responder
                      </Link>
                    </>
                  }
                </CardBody>
              </Card>
              {/* Respuestas */}
              {
                (comm_responses?.length > 0) &&
                <React.Fragment>
                  <h4>Respuestas</h4>
                  <Card>
                    <CardBody>
                      {comm_responses.map((comm) => (
                        <CommDetail key={comm.id} selectedComm={comm} isResponse={true} ></CommDetail>
                      ))
                      }
                    </CardBody>
                  </Card>
                </React.Fragment>
              }
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}
          >
            Nueva respuesta
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={e => {
              e.preventDefault();
              formik.handleSubmit();
              return false;
            }}>
              <Row className='mb-3'>
                <Col>
                  <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    editorState={editorState}
                    onEditorStateChange={handleEditorChange}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Input
                    name='attachedFiles'
                    className="form-control"
                    type="file"
                    id="formFile"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} />
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal);
              }}
            >
              Cerrar
            </Button>
            <Button type="button" color="primary" onClick={() => { formik.handleSubmit() }}>
              {
                (validating_status === CommActionState.LOADING) ?
                <Spinner className="spinner-border text-light" size="sm" role="status"></Spinner>
                :
                'Enviar'
              }
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <Modal
        isOpen={signModal}
        toggle={() => {
          toggleSignModal();
        }}
        centered={true}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              toggleSignModal();
            }}
          >
            Ingrese su contraseña para firmar
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={e => {
              e.preventDefault();
              signFormik.handleSubmit();
              return false;
            }}>
              <Row className="mb-3">
                <Col>
                  <Label htmlFor="password">Contraseña</Label>
                  <Input
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="Contraseña de firma"
                    onChange={signFormik.handleChange}
                    onBlur={signFormik.handleBlur}
                    value={signFormik.values.password}
                    invalid={!!(signFormik.touched.password && signFormik.errors.password)}
                  />
                  {signFormik.touched.password && signFormik.errors.password && (
                    <FormFeedback type="invalid">{signFormik.errors.password}</FormFeedback>
                  )}
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <StatusAlert
              status={validating_status}
              msg={validating_msg}
              progressBar={true}
              progressTime={timeToAct}
            />
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                toggleSignModal();
              }}
            >
              Cancelar
            </Button>
            <Button 
              type="button" 
              color="primary" 
              onClick={() => signFormik.handleSubmit()}
            >
              Firmar
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}
