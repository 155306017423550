import { Alert, Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Modal, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import React, { useEffect, useState } from "react";

import { formatBytes, getFileIcon } from "src/util/files";
import { LoginActionState, UploadFlow } from "src/util/types";
import { onFinishUploadCertificate, resetState } from "src/slices/Auth/Login/reducer";
import { RootState } from "src";
import { StatusAlert } from "src/components/Common/StatusAlert";
import { updatePassword, uploadCertificate, uploadRubric } from "src/slices/thunks";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import Breadcrumb from "../../components/Common/Breadcrumb";
import userIcon from "../../assets/images/users/user-icon.png";
import withRouter from "src/components/Common/withRouter";

const UserProfile = () => {
  document.title = "Perfil de usuario | Libro de Contratos Digital";

  const dispatch = useAppDispatch();

  const [uploadModal, setUploadModal] = useState(false);
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [rejectedFilesAlert, setRejectedFilesAlert] = useState(false)
  const [fileTypeFilter, setFileTypeFilter] = useState({})
  const [extension, setExtension] = useState('.pfx')
  const [uploadFlow, setLoadFlow] = useState(UploadFlow.CERTIFICATE)

  const { user, configProfileMsg, configProfileStatus, updatePasswordStatus, updatePasswordMsg } = useSelector((state: RootState) => state.login);


  function toggleCertifModal(uploadFlow?: UploadFlow) {
    if(uploadFlow) setLoadFlow(uploadFlow)
    setUploadModal(!uploadModal);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function onBtnClick(btnAction: UploadFlow) {
    dispatch(resetState())
    if (btnAction === UploadFlow.CERTIFICATE) {
      setFileTypeFilter({ 'application/x-pkcs12': ['.pfx']})
      setExtension('.pfx')
    } else if (btnAction === UploadFlow.RUBRIC) {
      setFileTypeFilter({ 'image/jpg': ['.jpg', '.jpeg'], 'image/png': ['.png'] })
      setExtension('.jpg, .jpeg o .png')
    }
    setselectedFiles([]);
    setRejectedFilesAlert(false);
    console.log('btnAction', btnAction)
    toggleCertifModal(btnAction);
  }

  function handleAcceptedFiles(files: any) {
    dispatch(resetState())
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        iconClass: getFileIcon(file.type)
      })
    );
    setselectedFiles(files);
  }

  const uploadFile = () => {
    if(!selectedFiles?.length) return;

    switch (uploadFlow) {
      case UploadFlow.CERTIFICATE:
        dispatch(uploadCertificate(selectedFiles[0]))
        break;
      case UploadFlow.RUBRIC:
        dispatch(uploadRubric(selectedFiles[0]))
        break;
    }
  }

  

  useEffect(() => {
    if(configProfileStatus === LoginActionState.SUCCESS) {
      const timeout = setTimeout(() => {
        setUploadModal(false);
        setselectedFiles([]);
        dispatch(onFinishUploadCertificate())
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [configProfileStatus])

  const passwordFormik= useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required('La nueva contraseña es requerida'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], 'Las contraseñas deben coincidir')
        .required('Debe confirmar la contraseña')
    }),
    onSubmit: ({newPassword}) => {
      dispatch(updatePassword(newPassword))
    }
  });

  const [passwordModal, setPasswordModal] = useState(false)

  function toggleSignModal() {
    dispatch(resetState())
    passwordFormik.resetForm();
    setPasswordModal(!passwordModal);
    removeBodyCss();
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Libro de Contratos Digital" breadcrumbItem="Perfil" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={userIcon}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center ms-3">
                      <div className="text-muted">
                        <h5>{user.name}</h5>
                        <p className="mb-1">{user.profile}</p>
                        <p className="mb-1">{user.email}</p>
                        <p className="mb-0">Id no: #{user.id}</p>
                      </div>
                    <div className="mt-3">
                      <Button
                        type="button"
                        color="primary"
                        onClick={() => toggleSignModal()}
                      >
                        <i className="bx bx-key me-1"></i>
                        Cambiar clave de acceso
                      </Button>
                    </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="mb-4">Configuración del usuario</h4>
          <Row>
            <Col lg={4}>
              <Card>
                <h5 className="card-header">Certificado de Firma Digital</h5>
                <CardBody>
                  <Row className="text-center">
                    <Col className="px-3">
                      <Alert className={classNames("col-12", {"alert-success" : user.hasCertificate}, {"alert-danger" : !user.hasCertificate})}>
                        Certificado de Firma Digital: <strong>{user.hasCertificate ? "Activo" : "Inactivo"}</strong>
                      </Alert>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center px-5">
                      <div className="col-lg-12">
                        <Button
                          type="button"
                          color="primary"
                          className="btn-block mb-1"
                          onClick={() => onBtnClick(UploadFlow.CERTIFICATE)}
                        >
                          <i className="bx bx-upload me-1"></i>
                          {user.hasCertificate ? "Actualizar" : "Cargar"} Certificado
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <Card>
                <h5 className="card-header">Rúbrica</h5>
                <CardBody>
                  <Row className="text-center">
                    <Col className="px-3">
                      <Alert className={classNames("col-12", {"alert-success" : user.hasRubric}, {"alert-danger" : !user.hasRubric})}>
                        Rúbrica: <strong>{user.hasRubric ? "Cargada" : "Sin cargar"}</strong>
                      </Alert>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center px-5">
                      <div className="col-lg-12">
                        <Button
                          type="button"
                          color="primary"
                          className="btn-block mb-1"
                          onClick={() => onBtnClick(UploadFlow.RUBRIC)}
                        >
                          <i className="bx bx-upload me-1"></i>
                          {user.hasRubric ? "Actualizar" : "Cargar"} Rúbrica
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={uploadModal}
        toggle={() => {
          toggleCertifModal();
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Seleccionar archivo</h5>
          <button
            type="button"
            onClick={() => {
              setUploadModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col className="col-12">
              <Form>
                <Dropzone
                  accept={fileTypeFilter}
                  onDrop={acceptedFiles => {
                    setRejectedFilesAlert(false)
                    handleAcceptedFiles(acceptedFiles);
                  }}
                  onDropRejected={ (_) => {
                    setRejectedFilesAlert(true)
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          <i className="display-4 text-muted uil uil-cloud-upload"></i>
                        </div>
                        <h4>Arrastra los documentos aquí o haz click para buscar en tu equipo.</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="dropzone-previews mt-3" id="file-previews">
                  {selectedFiles.map((f: any, i: number) => 
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                            <i className={ getFileIcon(f.iconClass) }></i>
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                className="text-muted font-weight-bold"
                              >
                                {f.name}
                              </Link>
                              <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                      )}
                </div>
              </Form>
              {rejectedFilesAlert && <Alert className="alert-danger mt-4">Solo se permiten archivos con extensión {extension}</Alert>}
              <div className="text-center mt-4">
                <Button color="primary" onClick={uploadFile}>
                  { uploadFlow === UploadFlow.CERTIFICATE ? "Subir certificado" : "Subir rúbrica" }
                </Button>
              </div>

              <StatusAlert status={configProfileStatus} msg={configProfileMsg} progressBar={true} progressTime={1000} ></StatusAlert>

            </Col>
          </Row>
        </div>
      </Modal>
    <Modal
      isOpen={passwordModal}
      toggle={() => setPasswordModal(!passwordModal)}
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Ingresa tu contraseña</h5>
        <button
          type="button"
          onClick={() => setPasswordModal(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={(e: any) => {
            e.preventDefault();
            passwordFormik.handleSubmit();
            return false;
          }}
        >
          <Row className="mb-3">
            <Label className="col-md-4 col-form-label">
              Nueva contraseña
            </Label>
            <Col md={8}>
              <Input
                name="newPassword"
                className="form-control"
                placeholder="Ingrese su nueva contraseña"
                type="password"
                onChange={passwordFormik.handleChange}
                onBlur={passwordFormik.handleBlur}
                value={passwordFormik.values.newPassword}
                invalid={ !!(passwordFormik.touched.newPassword && passwordFormik.errors.newPassword) }
              />
              {
                passwordFormik.touched.newPassword && passwordFormik.errors.newPassword &&
                <FormFeedback type="invalid">{passwordFormik.errors.newPassword}</FormFeedback>
              } 
            </Col>
          </Row>
          <Row className="mb-3">
            <Label className="col-md-4 col-form-label">
              Confirmar contraseña
            </Label>
            <Col md={8}>
              <Input
                name="confirmPassword"
                className="form-control"
                placeholder="Re-ingrese contraseña nueva"
                type="password"
                onChange={passwordFormik.handleChange}
                onBlur={passwordFormik.handleBlur}
                value={passwordFormik.values.confirmPassword}
                invalid={ !!(passwordFormik.touched.confirmPassword && passwordFormik.errors.confirmPassword) }
              />
              { 
                passwordFormik.touched.confirmPassword && passwordFormik.errors.confirmPassword &&
                <FormFeedback type="invalid">{passwordFormik.errors.confirmPassword}</FormFeedback>
              } 
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <Button color="primary" type="submit">
                  Confirmar
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <StatusAlert status={updatePasswordStatus} msg={updatePasswordMsg} ></StatusAlert> 
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);