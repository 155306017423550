import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Label, Input } from 'reactstrap'
import { useAppDispatch } from 'src/hooks/useAppDispatch'
import { contractChange } from 'src/slices/Auth/Login/reducer'
import { Contract } from 'src/util/types'

export const ContractSelector: React.FC<{ contracts: Contract[] }> = ({contracts}) => {
  
  const navigate = useNavigate();
  const dispatch = useAppDispatch() 

  const shortenName = (name: string) => {
    return name.length > 90 ? name.slice(0, 90) + '...' : name;
  };

  const onContractSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const contractId = event.target.value;
    const contract = contracts.find(contr => contr.id === contractId)
    if(contract) {
      dispatch(contractChange(contract))
      navigate('/repositorio/general')
    }
  }
  
  return (
    <div className="ms-2 d-flex align-items-center">
      {
        !contracts || contracts.length === 0 ? (
          <Label className="col-md-2 col-form-label w-auto p-2">
            No hay contratos disponibles.
          </Label>
        ) : contracts.length === 1 ? (
          <Label className="col-md-2 col-form-label w-auto p-2">
            {shortenName(contracts[0].name)}
          </Label>
        ) : (
          <>
            <Label className="col-md-2 col-form-label w-auto p-2">Contrato actual</Label>
            <Input 
            onChange={onContractSelected}
            type="select" 
            className="form-select" 
            defaultValue={contracts[0].id} 
            style={{ maxWidth: '300px' }}>
            {
              contracts.map((contr)=> (
                <option key={contr.id} value={contr.id} >{ shortenName(contr.name) }</option>
              ))
            }
          </Input>
          </>
        )
      }
    </div>
  )
}
