export const getFileIcon = (fileType: string): string => {
  // Map
  const fileIcons: { [key: string]: string } = {
    'application/pdf': 'fa fa-file-pdf text-danger',
    'application/msword': 'fa fa-file-word text-primary',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'fa fa-file-word text-primary',
    'application/vnd.ms-excel': 'fa fa-file-excel text-success',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'fa fa-file-excel text-success',
    'application/vnd.ms-powerpoint': 'fa fa-file-powerpoint text-warning',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'fa fa-file-powerpoint text-warning',
    'image': 'fa fa-file-image text-info',
    'video': 'fa fa-file-video text-purple',
    'audio': 'fa fa-file-audio text-warning',
    'text': 'fa fa-file-alt'  
  };  

  // Si el tipo de archivo existe en el mapa, lo devolvemos directamente
  if (fileIcons[fileType]) {
    return fileIcons[fileType];
  }

  // Casos específicos para image, video, audio y text
  const splitType = fileType.split('/')[0];

  if (fileIcons[splitType]) {
    return fileIcons[splitType];
  }

  // Icono por defecto para otros tipos de archivo
  return 'fa fa-file';
};

export const formatBytes = (bytes: any, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}