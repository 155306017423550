import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

import { resetCommsState } from "src/slices/Communications/reducer";
import { resetDraftState } from "src/slices/Draft Communications/reducer";
import { useAppDispatch } from "src/hooks/useAppDispatch";

const EmailToolbar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()

  const handleButtonClick = () => {
    dispatch(resetCommsState());
    dispatch(resetDraftState());
    navigate("/comunicaciones/nuevo"); 
  };

  return (
    <React.Fragment>
      <div className="btn-toolbar py-3" role="toolbar">
        <Button
          type="button"
          color="danger"
          className="btn btn-danger me-2 waves-effect waves-light fw-semibold"
          onClick={handleButtonClick}
        >
          Nueva comunicación
        </Button>
      </div>
    </React.Fragment>
  );
};

export default EmailToolbar;

